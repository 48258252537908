import React from "react"
import { Link } from "gatsby"
import Products from "../peaksuiteProducts"

const PeaksuiteTrailheadSection = ({ handleSubmit }) => (
  <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
    <section className="component pt-4 pb-0">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 peakSuite-product">
            <iframe
              src="https://drive.google.com/file/d/1MQyQv3FmMTxx9xOynwTEDWbHi70jJLBC/preview"
              frameBorder="0"
              width="60%"
              height="400"
              className="float-left mr-3 mb-3"
              style={{ boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)" }}
              title="trailhead"
            />
            <p>
              <span className="trailhead-color">peakSUITE | Trailhead</span> is
              a simple, easy to use tool for internal project management and
              client-facing initiatives. It tracks various project deliverables
              and due dates, guiding each one through various phases until
              official completion. Its calendar provides an accessible interface
              to easily monitor approaching milestones. And when someone
              requests new deliverables or milestones, you can easily implement
              functionality to approve, reject, or hold that request for a later
              date or future project.
            </p>
            <p>
              As a client-facing application, peakSUITE | Trailhead lets your
              customers review, track, and stay updated on all ongoing projects
              for their company. Each deliverable represents a different aspect
              of the project described by the stakeholders, point-by-point,
              milestone by milestone. peakSUITE | Trailhead allows stakeholders
              to make updates, request new deliverables, and sign off on
              completions. peakSUITE | Trailhead keeps your project stakeholders
              informed regularly, letting them know which stages their
              deliverables are in the system. Phases are customizable to fit any
              business. And as a Quickbase application, customizations,
              notifications, alternative dashboards, and reporting is only a few
              clicks away. For example, a project might go through these 4
              phases.
            </p>
            <ul className="list-unstyled fourPhases">
              <li>
                <strong className="trailhead-color">Incomplete</strong>: This
                item has not yet been built to specification.
              </li>
              <li>
                <strong className="trailhead-color">Ready for Demo</strong>:
                This item has been completed and is ready for review.
              </li>
              <li>
                <strong className="trailhead-color">Ready for Sign-Off</strong>:
                This item has been reviewed and is ready to be approved.
              </li>
              <li>
                <strong className="trailhead-color">Signed-Off</strong>: This
                item is completed and reviewed to satisfaction with stakeholder
                approval.
              </li>
            </ul>
            <p>
              Once all the items have been signed off, the project is officially
              complete. Any and all future deliverables that may be related to
              the project, but are not yet mapped out, can then be scheduled.
              peakSUITE | Trailhead is a simple, effective way to keep on top of
              your projects while maintaining constant communication with your
              client. It eliminates scope creep on projects, keeps stakeholders
              in the loop, and holds every member of the project accountable.
            </p>
            <div className="text-center">
              <Link
                to="/contact/"
                onClick={handleSubmit}
                className="btn btn-primary btn-arrow trailhead-bg"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
        <div className="row peaksuite-products mt-5">
          <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
            {" "}
            <div className="return-btn-trailhead ">
              <Link
                to="/peaksuite/"
                className="btn btn-primary-outline px-3"
                style={{
                  color: "#148090",
                  border: "1px solid #148090",
                  fontSize: "18px",
                }}
              >
                <i className="fa fa-caret-left mr-2" />
                Return
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-12 prod-order-1">
            <Products />
          </div>
        </div>
      </div>
    </section>
  </div>
)
export default PeaksuiteTrailheadSection
